
/* FOR ALL */

*{
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
}




/* FIRST SECTION FOR ACTIVITIES */
.activities-description {
    padding-top: 100px;
    /* background-color: white; */
}
.header {
    font-size: 60px;
}
.headers {
    display: none;
    padding: 0;
    margin: 0
}

.opening {
    box-shadow: 0px 0px 20px black
}

.opening, .orientation, .sessions,.conference, .trips, .awards {
    padding-top: 3%;
    padding-bottom: 3%;
    background-color: white;
}

.sliderDivLTR {
    text-align: left;
}
.sliderDivRTL {
    text-align: right;
}

.sliderDivLTR, .sliderDivRTL {
    display: flex;
    flex-direction:row ;
}
.slider {
    width: 60%;
    height: fit-content;
    margin-left: auto;
    margin-right: auto;
}
.single-item {
    width: 80%;
    padding: 10px;
    margin-left: auto;
    margin-right: auto;
    box-shadow: 0px 0px 50px rgb(91, 10, 122)
    /* border: 2px #343a40 solid; */
}
.sliderDescription {
    width: 40%;
    display: flex;
    flex-direction: column;
}

.openingPics, .orientationPics, .sessionPics, .conferencePics, .tripsPics, .awardsPics {
    width: 100%;
    height: 100%;
}
@media screen and (max-width:992px) {
    .header {
        font-size: 40px;
    }
    
    .sliderDescription {
        padding-top: 3%;
        text-align: center;
        width: 100%;
    }

    .mobileHeader {
        display: none;
    }
    .slider {
        width: 100%;
    }
    .single-item {
        padding: 6px;
        /* width: 100%; */
    }
    .sliderDivLTR {
        flex-direction: column;
    }
    .sliderDivRTL {
        flex-direction: column-reverse;
    }
    .sliderDivRTL, .sliderDivLTR {
        text-align: center !important;
    }
    .headers {
        display: unset;
    }
}
@media screen and (max-width:768px) {
    /* .opening-description, .orientation-description, .sessions-description, .trips-description, .awards-description {
        font-size: small;
    } */
}


.footerQuote {
    margin: 0;
    padding: 0;
}
.quote {
    margin: 0;
}