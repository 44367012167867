@media screen and (max-width:992px) {
    h1 {
        font-size: 30px !important;
    }
    h2 {
        font-size: 25px !important;
    }
}

@media screen and (max-width:768px) {
    h1 {
        font-size: 25px !important;
    }
    h2 {
        font-size: 20px !important;
    }
}