/* Footer */


footer {
    padding:1.5%;
    height: fit-content;
}

.footerContainer {
    max-width: 100%;
}

.footerContent {
    display: flex;
    flex-direction: column;
}

.socialMedia {
    margin-top: 2%;
    width: 130%;
    display: flex;
    flex-direction: column;
}

.socialMediaCols {
    width: 80%;
    display: flex;
    flex-direction: column;
}

.socialMediaCol {
    width: 24% !important;
    text-align: center;
}
.mail {
    width: 26%;
}

.link {
    margin-bottom: 14px;
    display: flex;
    align-items: center;
    text-decoration: none;
    color: #343a40;
    font-size: 19px !important;
    font-weight: bold;
}
.link:hover {
    color: rgb(99, 24, 164);
    transition: 0.5s;
}

.lastLogo {
    width: 8%;
    margin-top: -3%;
}

.footerLogo {
    width: 100%;
}

@media screen and (min-width:1570px) and (max-width:1700px) {
    .socialMedia {
        width: 120%;
    }
    .mail {
        width: 29%;
    }
}



@media screen and (min-width:1400px) and (max-width: 1570px) {
    .socialMedia {
        width: 120%;
    }
    .mail {
        width: 30%;
    }
}
@media screen and (min-width:1200px) and (max-width: 1370px) {
    .socialMedia {
        width: 120%;
    }
    .mail {
        width: 34%;
    }
}
@media screen and (min-width:992px) and (max-width: 1200px) {
    .socialMedia {
        width: 100%;
    }
    .socialMediaCol {
        width: 24%;
        margin-right: 5%;
    }
    .lastLogo {
        width: 11%;
    }
    .link {
        font-size: 15px;
    }
    .mail {
        width: 36%;
    }
}

@media screen and (max-width:992px) {
    footer {
        padding: 4%
    }
    .socialMedia {
        width: 100%;
    }
    .socialMediaCols {
        flex-direction: column;
    }
    .socialMediaCol {
        width: 95% !important;
        text-align: left;
        margin-top: 2%;
    }
    .mail {
        width: 100%;
    }
    .footerLogo {
        width: 90px
    }
}


@media screen and (max-width:510px) {
    .link {
        font-size: 16px;
    }
    .mainLogo {
        width: 130px;
    }
    .footerLogo {
        width: 80px
    }
}

@media screen and (max-width:400px) {
    .link {
        font-size: 12px;
    }
    .mainLogo {
        width: 130px;
    }
    .footerLogo {
        width: 70px;
    }
}