*{
    font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
    transition: 0.5s;
}

.media-description {
    padding-top: 100px;
}

.mediaRole {
    box-shadow: 0px 0px 20px black;
    padding-top: 2.5%;
}

.mediaTeam {
    box-shadow: 0px 0px 20px black
}

.mediaTeam {
    padding-top: 3%;
    padding-bottom: 3%;
    background-color: white;
}

.mediaPicDiv {
    width: 70%;
    margin-left: auto;
    margin-right: auto;
    z-index: 3;
    padding:10px;
    box-shadow: 10px 0px 50px rgb(91, 10, 122);
}