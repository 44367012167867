/* .section404 {
    margin-top: 15%;
} */

.errRow {
    display: flex;
    flex-direction: row;
}

.errLogo {
    width: 30%;
}

.errText {
    width: 60%;
    margin-top: 5%;
    margin-left: 5%;
}

.homebtn {
    min-width: 20%;
    max-width: fit-content;
    background-color: white !important ;
    color: darkslategrey !important;
}

@media screen and (max-width:992px) {
    .errRow {
        flex-direction: column;
        text-align: center;

    }
    .errContent {
        width: 90%;
        margin:padding
    }
    .errLogo {
        width: 50%;
        margin-left: auto;
        margin-right: auto;
    }
    .errText {
        width: 100%;
    }
}