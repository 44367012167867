.allDescription {
    font-size: 27px;
}
@media screen and (max-width:768px) {
    .allDescription {
        font-size: 20px;
        margin-left: 2%;
    }
}
@media screen and (min-width:320px) and (max-width:400px) {
    .allDescription {
        font-size: 17px;
        margin-left: 2%;
    }
}