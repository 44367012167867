.arrow {
    border: solid ;
    border-width: 0 3px 3px 0;
    display: inline-block;
    padding: 3px;
}
.left {
    transform: rotate(135deg);
    -webkit-transform: rotate(135deg)
}
.thanksWord {
    text-decoration: none;
    color: rgba(255, 255, 255, 0.55);
    font-weight: bold;
    font-size: 17px;
}
.specialThanks {
    padding-left: 3%;
}
.thanksWord:hover {
    color: white
}
.nav-thanks {
    display: none;
}

@media screen and (max-width: 992px) {
    .specialThanks {
        display: none;
    }
    .nav-thanks {
        display: unset;
    }
}
.navBarLogo {
    width: 80px;
}

@media screen and (max-width:992px) {
    nav {
        background-image: linear-gradient(to bottom, rgba(0,0,0) ,rgba(0,0,0,0.9),rgba(0,0,0,0.7),  transparent);
    }
}
.dropdown-menu {
    height: 0;
    visibility: hidden !important;
    opacity: 0 !important;
    overflow-y: hidden;
    transition-property: opacity, visibility, height !important;
    transition-duration: 0.4s, 0.4s !important;
    transition-delay: 0s, 0s !important;
}
.dropdown-menu.show, .dropdown-toggle.show {
    height: auto;
    visibility: visible !important;
    opacity: 1 !important;
    transition-delay: 0s, 0s !important;
}


@media screen and (max-width:992px) {
    .dropdown-menu {
        height: 0;
    }
    .dropdown-menu.show, .dropdown-toggle.show {
        height: auto;
    }
}


.nav-item {
    min-width: fit-content !important;
    margin-right: 5%;
    font-size: 20px;
    font-weight: bold;
}
.dropdown-menu {
    width: 270px;
}



.activeCommittee {
    background-image: linear-gradient(to right, #212121c4, #838383e1) !important;
    color: whitesmoke !important;
}

.committeeSelector, .profileSelector {
    font-weight: bold !important;
    letter-spacing: 0.4px;
    font-family: unset;
}


.committeeSelector:hover {
    background-color: unset !important;
    background-image: linear-gradient(to right, #212121c4, #838383e1);
    color: whitesmoke !important;
}
.profileSelector:hover {
    background-color: gray !important;
    color: whitesmoke !important;
}